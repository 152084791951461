import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '@/views/Login.vue';
import store from "@/store";
import ECardView from '@/views/ECardView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta:{
            authRequired: 'false'
        }
    },
    {
        path: '/e-card/:key',
        name: 'e-card',
        component: ECardView,
        meta:{
            authRequired: 'false'
        }
    },
    {
        path: "/supervisor/Employees/",
        name: "supervisor-employees",
        component: () => import("@/views/supervisor/EmployeesView"),
        meta:{
            authRequired: 'true',
            role: 'ProjectPeriodSupervisor'
        }
    },
    {
        path: "/supervisor/Requests/:id",
        name: "supervisor-Requests",
        component: () => import("@/views/supervisor/RequestsView"),
        meta:{
            authRequired: 'true',
            role: 'ProjectPeriodSupervisor'
        }
    },
    {
        path: "/supervisor/ProjectPeriodsCards/",
        name: "supervisor-ProjectPeriodsCards",
        component: () => import("@/views/supervisor/ProjectPeriodsCardsView"),
        meta:{
            authRequired: 'true',
            role: 'ProjectPeriodSupervisor'
        }
    },
    {
        path: "/supervisor/requests-to-receives/",
        name: "supervisor-requests-to-receives",
        component: () => import("@/views/supervisor/RequestsToReceivesView"),
        meta:{
            authRequired: 'true',
            role: 'ProjectPeriodSupervisor'
        }
    },
    {
        path: "/supervisor/ProjectPeriods/",
        name: "supervisor-ProjectPeriods",
        component: () => import("@/views/supervisor/ProjectPeriodsView"),
        meta:{
            authRequired: 'true',
            role: 'ProjectPeriodSupervisor'
        }
    },
    {
        path: "/supervisor/CarModels/",
        name: "supervisor-CarModels",
        component: () => import("@/views/supervisor/CarModelsView"),
        meta:{
            authRequired: 'true',
            role: 'ProjectPeriodSupervisor'
        }
    },
    {
        path: "/supervisor/print-reqeust/:key",
        name: "print-reqeust",
        component: () => import("@/views/supervisor/PrintReqeustView"),
        meta:{
            authRequired: 'true',
            role: 'ProjectPeriodSupervisor'
        }
    },
    {
        path: "/supervisor/",
        name: "supervisor",
        component: () => import("@/views/supervisor/IndexView"),
        meta:{
            authRequired: 'true',
            role: 'ProjectPeriodSupervisor'
        }
    },
    {
        path: "/supervisor/project-period-day/:id",
        name: "supervisor-project-period-day",
        component: () => import("@/views/supervisor/ProjectPeriodDayView"),
        meta:{
            authRequired: 'true',
            role: 'ProjectPeriodSupervisor'
        }
    },
    {
        path: "/admin/projects/",
        name: "admin-projects",
        component: () => import("@/views/admin/ProjectsView"),
        meta:{
            authRequired: 'true',
            role: 'Administrator'
        }
    },
    {
        path: "/admin/projects-periods/",
        name: "admin-projects-periods",
        component: () => import("@/views/admin/ProjectPeriodsView"),
        meta:{
            authRequired: 'true',
            role: 'Administrator'
        }
    },
    {
        path: "/admin/CarModels/",
        name: "admin-CarModels",
        component: () => import("@/views/admin/CarModelsView"),
        meta:{
            authRequired: 'true',
            role: 'Administrator'
        }
    },
    {
        path: "/admin/users/",
        name: "admin-users",
        component: () => import("@/views/admin/UsersView"),
        meta:{
            authRequired: 'true',
            role: 'Administrator'
        }
    },
    // {
    //     path: "/admin/reports-requests/",
    //     name: "admin-reports-reports",
    //     component: () => import("@/views/admin/ReportsView"),
    //     meta:{
    //         authRequired: 'true',
    //         role: 'Administrator'
    //     }
    // },
    // {
    //     path: "/admin/reports-Project-Period-Summary/",
    //     name: "admin-reports-reports-project-period-summary",
    //     component: () => import("@/views/admin/ReportsView"),
    //     meta:{
    //         authRequired: 'true',
    //         role: 'Administrator'
    //     }
    // },
    {
        path: "/admin/reports/",
        name: "admin-reports",
        component: () => import("@/views/admin/ReportsView"),
        meta:{
            authRequired: 'true',
            role: 'Administrator'
        },
        children: [{
            path: 'requests',
            component: () => import('@/components/Reports/RequestDetails.vue'),
          }, {
            path: 'project-period-summary',
            component: () => import('@/components/Reports/ProjectPeriodDaySummary.vue'),
          }]
    },

    {
        path: "/admin/",
        name: "admin",
        component: () => import("@/views/admin/IndexView"),
        meta:{
            authRequired: 'true',
            role: 'Administrator'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta:{
            authRequired: 'false'
        }
    },
    {
        path: '/profile',
        name: 'profile',
        // lazy-loaded
        component: () => import('@/views/Profile.vue'),
        meta:{
            authRequired: 'true',
            role: 'Administrator'
        }
    },
    {
        path: '/Unauthorized',
        name: 'Unauthorized',
        // lazy-loaded
        component: () => import('@/views/UnauthorizedView.vue'),
        meta:{
            authRequired: 'true'
        }
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    // {
    //     path: '/:catchAll(.*)*',
    //     name: "PageNotFound",
    //     component: PageNotFound,
    //   },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {
    /*
    const publicPages = ['login', 'about', 'home', 'e-card'];
    const isPublicPath = publicPages.includes(to.name);
    const authRequired = !publicPages.includes(to.name);
    const loggedIn = store.getters['auth/isAuthenticated']; // localStorage.getItem('user');
    //const loggedIn = store.state.auth.isAuthenticated; //localStorage.getItem('user');

    console.log(to.name);
    console.log('loggedIn:' + loggedIn);

    if (isPublicPath) {
        next();
        return;
    }

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    }
    else {
        next();
    }
    */

    const loggedIn = store.getters['auth/isAuthenticated'];
    const userRole = store.getters['auth/userRole'];

    if(to.meta.authRequired === 'true' && !loggedIn)
    {
            next('/login');
    }

    if(to.path === '/Unauthorized')
    {
        next();
    }
    else if(to.meta.authRequired === 'true' && to.meta.role === userRole)
    {
       next();
    }
    else if(to.meta.authRequired === 'true' && to.meta.role != userRole)
    {
        next('/Unauthorized');
    }
   
    next();


});

export default router
